import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";


import patternHex from "../../images/backrounds/pattern-hex-white.jpg";
import InfoContactUsForm from "../../components/info-contact-us-form";

const subscriberLpMigrateToZendeskFormTag = process.env.GATSBY_SUBSCRIBER_LP_MIGRATE_TO_ZENDESK_FORM_TAG;

const Hero = () => (
  <div
    className="hero mt-0"
    style={{
      backgroundImage: `url(${patternHex})`,
    }}
  >
    <div className="hero-title bg-dark-transparent">
      <div className="hero-image">
        <StaticImage
          src="../images/clients/zendesk.png"
          alt="Zendesk Implementation Premier Partner"
          layout="constrained"
          style={{ maxWidth: "200px"}}
        />
      </div>
      <h1 className="text-white">
        “I never want to use another customer service ticketing system.”
      </h1>

      <h3>
        This could be what YOU’RE saying in a few months!
      </h3>

      <p className="text-white">
        Our clients tell us over and over how Zendesk makes supporting their
        customers simple… and we want to make your help desk easy to run, too.
      </p>

      <AnchorLink to="#get-started" className="btn btn-primary">Get Started</AnchorLink>
    </div>
  </div>
);

const Main = ({location}) => (

  <section className="pt-6" id="get-started">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8 mb-6 mb-md-0">
          <h2>
            When You Sign Up For Zendesk Through 729 Solutions, You Can Get Up To 30% Discount On Seats.
          </h2>
          <h5>Why?</h5>
          <h5>Because we’re a Zendesk Premier Partner, AMER Partner of the Year, and Master Implementer. Pretty fancy
            titles, huh? Our moms think so, too...</h5>
          <p>But what that means is that we can offer our customers a discount that keeps paying off every month.</p>
          <h3>So You Get A System That Really Works.</h3>
          <p>And you can actually USE it because you easily understand how to get it to do what you need.</p>
        </div>
        <div className="col-12 col-md-6 col-lg-4 bg-light py-4">
          <h3 className="text-center">
            Want An Easy, Simple Customer Service Platform? At A Lower Price?
          </h3>
          <div>
            <InfoContactUsForm
              tags={[subscriberLpMigrateToZendeskFormTag]}
              location={location}
              buttonText="Improve my customer service"
              onSubmitSuccess={() => navigate('/lp/migrate-to-zendesk-ty')}/>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Trends = () => (
  <section className="pt-6">
    <div className="container">
      <div className="row row-cols-1 row-cols-md-3">
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">On average, Zendesk increases customer satisfaction by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">25%*</p>
            </div>
          </div>
        </div>
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">On average, Zendesk increases agent satisfaction by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">30%*</p>
            </div>
          </div>
        </div>
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">Customers will spend more to buy from a company that offers good customer service
                by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">75%*</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col">
          <p>* CX Trends Report 2021</p>
        </div>
      </div>
    </div>
  </section>
);

const Quotes = () => (
  <section className="pt-6">
    <div className="container">
      <div className="row text-center">
        <div className="col">
          <h3>So... Would You Like To Have More Customers?<br/>Who Are Happier? </h3>
          <h2>Here’s What A Few Users Have Said<br/>About Their Zendesk Experiences:</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;It helps our teams maintain the personalization that our clients need while quickly solving their questions or help requests. The best part?
            Our customers feel genuinely supported and heard.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;We used Zendesk at 3 of my last 4 jobs. The last 2 at my suggestion. We handle a diverse amount of different customer issues and
            it's a great system to manage and track all the different interactions our customers are having as well as the agents that are handling them.&quot;"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;I can't stress enough
            how easy Zendesk makes it to pass tickets back and forth between people and teams.
            My team gets a constant flow of tickets and we are able to keep them organized by time, urgency, and topic.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;Zendesk really is the best CRM I have ever used, and I was in customer service for 10 years. By far and away
            the most intuitive, simple, and clean user experience you will have for a consumer-facing CR interface.&quot;
            "
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Hero/>
    <Main location={location}/>
    <Trends/>
    <Quotes/>
  </Layout>
);

export default Index;
